<template>
  <div class="table-responsive" v-if="list.length > 0">
    <table class="table table-striped table-hover" style="width:100%">
      <thead>
        <tr>
          <th v-for="(item, key) in fields" :key="key">{{ $t(item) }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in list" :key="row.id" @click="openDetail(row)">
          <td :data-label="$t('senderName')">{{ row.sender_name }}</td>
          <td class="col-md-4" :data-label="$t('forms.senderName.status')">
            {{ getLocalizedOrValue('forms.senderName.statuses.' + row.status) }}
            <span v-if="row.status === 'pending'" class="font-weight-bold"><br>{{ $t(`forms.senderName.pendingDescription`) }}</span>
            <span v-if="row.status === 'suspended'" class="font-weight-bold"><br>{{ $t(`forms.senderName.suspendedShortDescription`) }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useHumanDate } from '@/application/composables/humanDate.js'
import { getLocalizedOrValue } from '@/application/utils/localization'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

export default {
  name: 'CurrentSenderNamesTable',
  props: {
    list: {
      type: Array,
      required: true,
    },
    onAfterDelete: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const router = useRouter()
    const i18n = useI18n()
    const getLocalizedOrValueCurried = getLocalizedOrValue.bind(null, i18n)
    const { humanDate } = useHumanDate()
    const fields = ref(['senderName', 'forms.senderName.status'])

    return {
      humanDate,
      fields,
      getLocalizedOrValue: getLocalizedOrValueCurried,
      openDetail: (senderName) => {
        router.push({ name: 'detail-sender-name', params: { id: senderName.sender_name_id } })
      },
    }
  },
}
</script>
